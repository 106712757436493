import React from "react";
import Navbar from "./components/navbar/index";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate
} from "react-router-dom";
import Home from "./pages/home/index";
import Footer from "./components/footer";
import AffiliateLogin from "./pages/auth/login/AffiliateLogin";
import ForgetPassword from "./pages/auth/forgetPassword";
import ResetPassword from "./pages/auth/resetPassword";
import Campaign from "./pages/campaign";
import CampaignDetail from "./pages/campaign/CampaignDetail";
import AdminCampaignDetail from "./pages/admin/campaigns/CampaignDetail";
import AdminNavbar from "./components/navbar/admin";
import AdminHome from "./pages/admin/home";
import Users from "./pages/admin/users";
import Campaigns from "./pages/admin/campaigns";
import DonationTracking from "./pages/admin/DonationTracking";
import OrganizationLogin from "./pages/auth/login/OrganizationLogin";
import AffiliateSignup from "./pages/auth/signup/AffiliateSignup";
import OrganizationSignup from "./pages/auth/signup/OrganizationSignup";
import SuperAdminCampagn from "./superAdmin/pages/campaings";
import SuperAdminHome from "./superAdmin/pages/home";
import SuperAdminNavbar from "./superAdmin/components/navigation";
import Login from "./superAdmin/pages/auth/Login";
import Raisers from "./superAdmin/pages/raisers";
import SuperadminCampDetais from "./superAdmin/pages/campaings/SuperadminCampDetails";
import SuperUsersDetails from "./superAdmin/pages/users";
import AffiliateProfile from "./pages/auth/profile";
import AboutUs from "./pages/aboutus";
import SuperAdminPaymentTracking from "./superAdmin/pages/payments";
import PaymentsTracking from "./pages/paymentTracking";
import ContactUs from "./pages/contact";
import Messages from "./superAdmin/pages/messages";

const App = () => {
  return (
    <Router basename="/">
      <Routes>
        <Route path="/affLogin" element={<AffiliateLogin />} />
        <Route path="/orgLogin" element={<OrganizationLogin />} />
        <Route path="/affSignup" element={<AffiliateSignup />} />
        <Route path="/orgSignup" element={<OrganizationSignup />} />
        <Route path="/profile" element={<AffiliateProfile />} />
        <Route path="/superAdminlogin" element={<Login />} />

        <Route path="/forget" element={<ForgetPassword />} />
        <Route path="/reset" element={<ResetPassword />} />

        <Route path="/*" element={<AppContent />} />
        <Route path="/admin/*" element={<AdminContent />} />
        <Route path="/superadmin/*" element={<SuperAdminContent />}>

        </Route>
      </Routes>
    </Router>
  );
};

const SuperAdminContent = () => {
  const location = useLocation();
  const isSuperAdminLoggedIn = localStorage.getItem("superAdmin");

  return (
    <>
      {location.pathname !== "/superadmin/campaignDetail" && (
        <SuperAdminNavbar />
      )}
      {isSuperAdminLoggedIn ? <Routes>
        <Route path="/" element={<SuperAdminHome />} />
        <Route path="/raisers" element={<Raisers />} />
        <Route path="/users" element={<SuperUsersDetails />} />
        <Route path="/campaigns" element={<SuperAdminCampagn />} />
        <Route path="/payments" element={<SuperAdminPaymentTracking />} />
        <Route path="/campaignDetail" element={<SuperadminCampDetais />} />
        <Route path="/messages" element={<Messages />} />
      </Routes> : <Navigate to="/superAdminlogin" />
    }
      
    </>
  );
};

const AdminContent = () => {
  const location = useLocation();

  return (
    <>
      <AdminNavbar />
      <Routes>
        <Route path="/" element={<AdminHome />} />
        <Route path="/users" element={<Users />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/campDetail" element={<AdminCampaignDetail />} />
        <Route path="/trackCampaign" element={<DonationTracking />} />

        {/* Add more admin-related routes here if needed */}
      </Routes>
    </>
  );
};

const AppContent = () => {
  const location = useLocation();

  return (
    <>
      {(location.pathname !== "/login" ||
        location.pathname !== "./signup" ||
        location.pathname === "./forget" ||
        location.pathname === "./reset") && <Navbar />}
      {location.pathname === "/adminHome" && <AdminNavbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/campaign" element={<Campaign />} />
        <Route path="/campaignDetail" element={<CampaignDetail />} />
        <Route path="/paymentTracking" element={<PaymentsTracking />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      {/* {(location.pathname !== "/login" ||
        location.pathname !== "./signup" ||
        location.pathname === "./forget" ||
        location.pathname === "./reset") && <Footer />} */}
    </>
  );
};

export default App;
